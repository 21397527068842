<template>
  <div>
    <b-container class="mt-3 min-h-100">
      <b-row class="mb-1">
        <b-col lg="2" class="d-none d-lg-flex">Branch</b-col>

        <b-col lg="7" class="d-none d-lg-flex"> Webhook Url </b-col>

        <b-col
          lg="2"
          class="f-14 underline cursor-pointer text-highlight text-right"
          @click="openModalPreview(data)"
        >
          example Json</b-col
        >
      </b-row>
      <b-row v-for="item in data" :key="item.id" class="">
        <b-col lg="2">
          <div class="d-lg-none mb-1">Branch</div>
          <InputSelect
            name="type"
            valueField="id"
            textField="name"
            v-model="item.branch_id"
            :value="item.branch_id"
            :options="branchList"
          >
            <template v-slot:option-first>
              <b-form-select-option value="0" disabled
                >-- Select Branch --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col lg="9">
          <InputText
            placeholder="Webhook Url"
            customLabel
            type="text"
            v-model="item.webhook_url"
          >
            <template v-slot:textFloat>
              <div class="d-lg-none mb-1">Webhook Url</div>
            </template>
          </InputText>
        </b-col>
        <b-col lg="1">
          <b-button
            variant="link"
            class="text-error"
            @click="deleteItemModal(item)"
          >
            <font-awesome-icon
              icon="trash-alt"
              title="Delete"
              class="main-color"
            />
          </b-button>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center">
        <b-button class="main-color btn-save" @click="addItem">Add</b-button>
      </div>
    </b-container>
    <FooterAction @submit="update(0)" routePath="/setting" />
    <ModalPreviewBody :tabIndex="1" ref="modal"></ModalPreviewBody>
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import ModalPreviewBody from "../modalPreviewBody.vue";

export default {
  components: { InputText, ModalPreviewBody, InputSelect },
  data() {
    return {
      data: [],
      dataUpdate: [],
      branchList: [],
      example_json: {},
    };
  },
  methods: {
    async getData() {
      const response = await this.axios(`/webhook/ListInventory`);
      this.data = response.data.detail.inventory_url_list;
      this.example_json = response.data.detail.example_json;
      this.getBranchList();
    },
    async getBranchList() {
      const data = await this.axios(`/webhook/ListBranchDropDown`);
      this.branchList = data.data.detail;
    },
    async update() {
      this.$bus.$emit("showLoading");
      const response = await this.axios.post(
        `/webhook/UpdateInventory`,
        this.data
      );
      this.getData();
      this.$bus.$emit("hideLoading");
      if (response.data.result == 1) this.successAlert();
      else this.errorAlert(response.data.message);
    },
    deleteItemModal(item) {
      this.confirmAlert().then((val) => {
        if (val.isConfirmed) {
          this.deleteItem(item);
        }
      });
    },
    async deleteItem(item) {
      if (item.id != 0) {
        this.$bus.$emit("showLoading");
        item.is_delete = true;
        const response = await this.axios.post(`/webhook/UpdateInventory`, [
          item,
        ]);
        this.getData();
        this.$bus.$emit("hideLoading");
        if (response.data.result == 1) this.successAlert();
        else this.errorAlert(response.data.message);
      } else {
        this.data = this.data.filter((x) => x !== item);
      }
    },
    addItem() {
      this.data.push({
        id: 0,
        webhook_url: "",
        status: 1,
        is_display: 1,
        branch_id: 0,
        is_delete: false,
      });
    },
    openModalPreview(example_json) {
      this.$refs.modal.openModal(example_json);
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.text-highlight {
  color: var(--primary-color);
  font-weight: bold;
  opacity: 1 !important;
  text-decoration: underline;
}

.delete-buttton {
  color: #575757;
  background-color: #fff;
  border: 1px solid #575757;
}

.min-h-100 {
  min-height: 100%;
}
</style>
